// import ReduxThunk from 'redux-thunk';
import { createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { persistStore, persistReducer, createTransform } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import reducers from 'reducers/index';
import rootSaga from 'sagas/root';
import keycloakMiddleware from 'sagas/keycloakMiddleware';

const COOKIE_ACCEPT_LIFESPAN_IN_MONTH = 6;

function getRehydratedAppState(appState) {
  const { acceptCookieDate, showCookieInfo, showAlertInfo } = appState;
  if (showCookieInfo) {
    return appState;
  }

  if (!acceptCookieDate) {
    return { acceptCookie: false, acceptCookieDate: null, showCookieInfo: true, showAlertInfo };
  } else {
    const now = new Date();
    const maxDate = new Date(acceptCookieDate);
    maxDate.setMonth(maxDate.getMonth() + COOKIE_ACCEPT_LIFESPAN_IN_MONTH);

    if (now > maxDate) {
      return { acceptCookie: false, acceptCookieDate: null, showCookieInfo: true, showAlertInfo };
    }
  }

  return appState;
}

const appStateTransform = createTransform(
  (inboundState) => inboundState,
  getRehydratedAppState,
  { whitelist: ['appState'] }
);

const sagaMiddleware = createSagaMiddleware();
const persistConfig = {
  key: 'root',
  storage,
  whitelist: [
    'appState',
    'newsletterState',
    'tagsState',
    'menuState',
  ],
  transforms: [appStateTransform],
};
const persistedReducer = persistReducer(persistConfig, reducers);

export const store = createStore(persistedReducer, {}, applyMiddleware(sagaMiddleware, keycloakMiddleware));
export const persistor = persistStore(store);

sagaMiddleware.run(rootSaga);
