// @flow
import React, { PureComponent } from 'react';

import { withHelmet } from 'infrastructure/seo/Helmet';
import type { MenuElementType } from 'types/MenuElement';
import type { liensRsType } from 'types/Settings';
import { Link } from 'react-router-dom';
import FooterSelect from 'components/footer/FooterSelect';
import { getBaseUrl } from 'constants/api';
import { getGGMapsLink } from 'utils/urlUtils';

import './FooterBottomStyle.css';

type Props = {
  footerElements: MenuElementType[],
  footerCorporateElements: MenuElementType[],
  adresse: {
    complement: string,
    localite: string,
    codePostal: string,
    voie: string,
    lieuDitBoitePostale: string,
    paysNom: string,
    immeuble: string,
    codeInsee: string,
    position: {
      type: string,
      coordinates: number[]
    },
    email: string
  },
  banniere: string,
  clubName: string,
  logo: string,
  liens_rs: liensRsType,
  placeholder: string,
  telephone: string,
  title: string
};

class FooterBottom extends PureComponent<Props> {
  static defaultProps = {
    footerElements: []
  };

  renderFooterCategory = () => {
    const { footerElements } = this.props;

    const categories = [];
    let categoriesCount = 0;
    footerElements.forEach((footerCategory, index) => {
      categories[categoriesCount] = categories[categoriesCount] || [];
      categories[categoriesCount].push(<FooterSelect key={index} footerCategory={footerCategory} />);
      if ((index + 1) % 4 === 0) {
        // the 4 here is how many items you want per row
        // if we can perfectly divide the current position by 4
        // we have filled a row, so now increment the row
        categoriesCount++;
      }
    });
    return categories;
  };

  renderCorporateLinks = () => {
    const { footerCorporateElements } = this.props;
    return (
      footerCorporateElements &&
      footerCorporateElements.map(({ title, slug_complet, object_slug, url, target }) => (
        <li key={`corporate_${url}`}>
          {slug_complet && (
            <Link to={slug_complet} title={title}>
              {title}
            </Link>
          )}
          {!slug_complet && (
            <a href={url} target={target} title={title}>
              {title}
            </a>
          )}
        </li>
      ))
    );
  };

  render() {
    const {
      adresse: { voie, lieuDitBoitePostale, complement, codePostal, localite, position, email },
      telephone,
      logo,
      title
    } = this.props;

    const categories = this.renderFooterCategory();

    const capitalizedVoie = `${
      voie
        ? voie
            .toLowerCase()
            .split(' ')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ')
        : ''
    }`;
    const capitalizedLieuDitBoitePostale = `${
      lieuDitBoitePostale
        ? lieuDitBoitePostale
            .toLowerCase()
            .split(' ')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ')
        : ''
    }`;
    const capitalizedComplement = `${
      complement
        ? complement
            .toLowerCase()
            .split(' ')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ')
        : ''
    }`;
    const capitalizedLocalite = `${
      localite
        ? localite
            .toLowerCase()
            .split(' ')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ')
        : ''
    }`;

    const long = position && !!position.coordinates.length ? position.coordinates[0] : false;
    const lat = position && !!position.coordinates.length ? position.coordinates[1] : false;
    let ggMapsLink = false;
    if (lat && long) {
      ggMapsLink = getGGMapsLink(
        [lat, long],
        `${capitalizedVoie || ''} ${capitalizedLieuDitBoitePostale || ''} ${capitalizedComplement || ''} ${capitalizedLocalite ||
          ''} ${codePostal || ''}`
      );
    }

    const internationalPhoneNumber =
      telephone && telephone.slice(0, 1) === '0' ? `+33${telephone.substr(1)}` : telephone ? telephone : false;

    return (
      <>
        <div className="container footer__links">
          <div className="footer__contact-detail">
            {ggMapsLink ? (
              <div className="footer__address">
                <a rel="noopener noreferrer" href={ggMapsLink} title="Voir l'adresse sur Google Map  (nouvel onglet)" target="_blank">
                  <i className="icon icon-place is-inline"></i>
                  <div className="footer__address-detail">
                    <p>{capitalizedVoie}</p>
                    <p>{capitalizedLieuDitBoitePostale}</p>
                    <p>{capitalizedComplement}</p>
                    <p>
                      {codePostal} {capitalizedLocalite}
                    </p>
                  </div>
                </a>
              </div>
            ) : (
              <div className="footer__address">
                <i className="icon icon-place"></i>
                <div className="footer__address-detail">
                  <p>{capitalizedVoie}</p>
                  <p>{capitalizedLieuDitBoitePostale}</p>
                  <p>{capitalizedComplement}</p>
                  <p>
                    {codePostal} {capitalizedLocalite}
                  </p>
                </div>
              </div>
            )}

            {internationalPhoneNumber && (
              <div className="footer__phone-number">
                <a href={`tel:${internationalPhoneNumber}`} target="_blank" rel="noreferrer">
                  <i className="icon icon-phone is-inline"></i>
                  <span className="content">{internationalPhoneNumber}</span>
                </a>
              </div>
            )}

            {!!email && (
              <div className="footer__email">
                <a href={`mailto:${email}`}>
                  <i className="icon icon-mail"></i>
                  <p>{email}</p>
                </a>
              </div>
            )}
          </div>

          <div className="container footer__bottom-nav">
            {categories.map((ar, i) => (
              <div key={i} className="row">
                {ar}
              </div>
            ))}
          </div>

          <div className="footer__logo mt-3 mt-lg-0">
            <span className="badge-score">
              <img src={logo} alt={`Logo officiel de la ${title}`} />
            </span>
          </div>
        </div>
        <div className="footer__corporate">
          <div className="container">
            <div className="row">
              <div className="col col-lg-8">
                <ul className="footer__corporate-links">
                  {this.renderCorporateLinks()}
                  {
                    <li>
                      <a href={getBaseUrl() + '/wp-admin/'} rel="nofollow">
                        Administration
                      </a>
                    </li>
                  }
                </ul>
              </div>
              <div className="col col-lg-4">
                <p className="footer__corporate-legal">
                  © 2019-{new Date().getFullYear()} {title}
                </p>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withHelmet(FooterBottom)((Helmet, pageProps) => {
  const {
    adresse: { voie, lieuDitBoitePostale, complement, codePostal, localite, position },
    banniere,
    clubName,
    liens_rs,
    logo,
    placeholder,
    telephone
  } = pageProps;

  const sameAs = Object.keys(liens_rs)
    .map(key => {
      return liens_rs[key];
    })
    .filter(value => {
      return typeof value === 'string' && value !== '';
    })
    .map(link => {
      return typeof link === 'string' && `"${link}"`;
    })
    .join(', ');

  const capitalizedVoie = `${
    voie
      ? voie
          .toLowerCase()
          .split(' ')
          .map(word => word.charAt(0).toUpperCase() + word.slice(1))
          .join(' ')
      : ''
  }`;
  const capitalizedLieuDitBoitePostale = `${
    lieuDitBoitePostale
      ? lieuDitBoitePostale
          .toLowerCase()
          .split(' ')
          .map(word => word.charAt(0).toUpperCase() + word.slice(1))
          .join(' ')
      : ''
  }`;
  const capitalizedComplement = `${
    complement
      ? complement
          .toLowerCase()
          .split(' ')
          .map(word => word.charAt(0).toUpperCase() + word.slice(1))
          .join(' ')
      : ''
  }`;
  const capitalizedLocalite = `${
    localite
      ? localite
          .toLowerCase()
          .split(' ')
          .map(word => word.charAt(0).toUpperCase() + word.slice(1))
          .join(' ')
      : ''
  }`;

  const internationalPhoneNumber = telephone.slice(0, 1) === '0' ? `+33${telephone.substr(1)}` : telephone;

  return (
    <Helmet>
      <link rel={'canonical'} href={window.location.href} />
      <script type="application/ld+json">
        {`[
            {
              "@context": "https://schema.org",
              "@type": "SportsActivityLocation",
              "name": "${clubName || 'Mon site club - FFR'}",
              "url": "https://${window.location.host}",
              "image": "${placeholder ?? banniere}",
              "logo": "${logo}",
              "sameAs": [${sameAs}],
              "address": {
                "@type": "PostalAddress",
                "streetAddress": "${capitalizedVoie ?? ''} ${capitalizedLieuDitBoitePostale ?? ''} ${capitalizedComplement ?? ''}",
                "addressLocality": "${capitalizedLocalite ?? ''}",
                "postalCode": "${codePostal ?? ''}",
                "addressCountry": "FR"
              },
              "geo": {
                "@type": "GeoCoordinates",
                "latitude": ${position && position.coordinates && position.coordinates[1] ? position.coordinates[1] : ''},
                "longitude": ${position && position.coordinates && position.coordinates[0] ? position.coordinates[0] : ''}
              },
              "telephone": "${internationalPhoneNumber}"
            },  
            {
              "@context": "https://schema.org",
              "@type": "WebSite",
              "url": "https://${window.location.host}",
              "potentialAction": {
                "@type": "SearchAction",
                "target": "https://${window.location.host}/recherche?s={search_term_string}",
                "query-input": "required name=search_term_string"
              }
            }
          ]`}
      </script>
    </Helmet>
  );
});
