// @flow
import { takeLatest, put, call, all, select, take } from 'redux-saga/effects';

import type { Saga } from 'redux-saga';

import type { GeneratorType } from 'sagas/root';

import { STATUS_SUCCESS } from 'constants/statusConstants';

import type {
  FETCH_RENCONTRE_START_ACTION,
  FETCH_RENCONTRE_ESSENTIALS_START_ACTION,
} from 'actions/rencontreActions';

import { fetchPageNode } from 'actions/pageActions';
import { FETCH_SETTINGS_SUCCESS } from 'actions/appActions';
import { rankingFetched } from 'actions/team/rankingActions';
import {
  FETCH_RENCONTRE_START,
  FETCH_RENCONTRE_ESSENTIALS_START,
  rencontreFetched,
  rencontreArticleFetched,
  rencontreFetchError,
} from 'actions/rencontreActions';

import { fetchAlbum } from 'actions/albumActions';

import {
  fetchRencontreFromGraph,
  fetchRencontreArticleFromGraph,
  fetchLastRencontresFromGraph,
} from 'api/graphApi/RencontreApi';

export default function() {
  return function* rencontreSaga(): GeneratorType {
    yield takeLatest(FETCH_RENCONTRE_START, fetchRencontre);
    yield takeLatest(FETCH_RENCONTRE_ESSENTIALS_START, fetchRencontreArticle);
  };

  function* fetchRencontre(action: FETCH_RENCONTRE_START_ACTION): Saga<void> {
    try {
      const lastRencontres = [];
      const { id, slugEquipe } = action.payload;
      const { settingsState: { status } } = yield select();

      if (status !== STATUS_SUCCESS) {
        yield take(FETCH_SETTINGS_SUCCESS);
      }

      const { settingsState: { club_id } } = yield select();
      const { rencontre, ranking, rankingCompetition } = yield call(fetchRencontreFromGraph, parseInt(id, 10), club_id);

      const structure = [];
      const regroupement = [];

      if (rencontre.localStructure.isRegroupement) {
        regroupement.push({ id: rencontre.localStructure.id });
      } else {
        structure.push({ id: rencontre.localStructure.id });
      }

      if (rencontre.visiteurStructure.isRegroupement) {
        regroupement.push({ id: rencontre.visiteurStructure.id });
      } else {
        structure.push({ id: rencontre.visiteurStructure.id });
      }

      if (rencontre.etat === 'Planifiée' || rencontre.etat === 'Annulée') {
        const { classeAgeId } = rencontre;

        const rencontres = yield call(
          fetchLastRencontresFromGraph,
          regroupement,
          structure,
          [parseInt(classeAgeId, 10)]
        );

        lastRencontres.push(...rencontres);
      }

      const { competition: { id: compID } } = rencontre;

      if (slugEquipe && slugEquipe !== null && compID) {
        yield all([
          put(fetchPageNode(slugEquipe)),
          put(fetchAlbum('', 'rencontre='+id+'&limit=1'))
        ]);
      }

      yield put(rankingFetched(rankingCompetition));
      yield put(rencontreFetched({ rencontre, ranking, lastRencontres }));
    } catch (e) {
      yield put(rencontreFetchError(e.message));
    }
  }

  function* fetchRencontreArticle(action: FETCH_RENCONTRE_ESSENTIALS_START_ACTION): Saga<void> {
    try {
      const { id } = action.payload;

      const rencontre = yield call(fetchRencontreArticleFromGraph, parseInt(id, 10));
      yield put(rencontreArticleFetched(rencontre));
    } catch (e) {
      yield put(rencontreFetchError(e.message));
    }
  }
}
