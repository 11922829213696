// @flow

import type {
  RencontreType,
  RencontreSimpleType,
  RencontreArticleType,
  RencontreActionContainerType,
} from 'types/Rencontre';
import type { RankingType } from 'types/Ranking';

export const FETCH_RENCONTRE_START = 'FETCH_RENCONTRE_START';
export const FETCH_RENCONTRE_ESSENTIALS_START = 'FETCH_RENCONTRE_ESSENTIALS_START';
export const FETCH_RENCONTRE_ESSENTIALS_SUCCESS = 'FETCH_RENCONTRE_ESSENTIALS_SUCCESS';
export const FETCH_RENCONTRE_SUCCESS = 'FETCH_RENCONTRE_SUCCESS';
export const FETCH_RENCONTRE_FAILURE = 'FETCH_RENCONTRE_FAILURE';
export const SET_RENCONTRE = 'SET_RENCONTRE';

export type FETCH_RENCONTRE_START_ACTION = {
  type: 'FETCH_RENCONTRE_START',
  payload: {
    id: string,
    slugEquipe?: string,
  },
};

export type FETCH_RENCONTRE_FAILURE_ACTION = {
  type: 'FETCH_RENCONTRE_FAILURE',
  payload: {
    error: string
  },
};

export type FETCH_RENCONTRE_SUCCESS_ACTION = {
  type: 'FETCH_RENCONTRE_SUCCESS',
  payload: {
    rencontre : RencontreType,
    ranking: RankingType[],
    lastRencontres: RencontreSimpleType[],
  },
};

export type FETCH_RENCONTRE_ESSENTIALS_START_ACTION = {
  type: 'FETCH_RENCONTRE_ESSENTIALS_START',
  payload: {
    id: string
  },
};

export type FETCH_RENCONTRE_ESSENTIALS_SUCCESS_ACTION = {
  type: 'FETCH_RENCONTRE_ESSENTIALS_SUCCESS',
  payload: {
    rencontre : RencontreArticleType,
  },
};

export type SET_RENCONTRE_ACTION = {
  type: 'SET_RENCONTRE',
  payload: {
    actions: RencontreActionContainerType[],
  },
}

export type Action =
  | FETCH_RENCONTRE_START_ACTION
  | FETCH_RENCONTRE_FAILURE_ACTION
  | FETCH_RENCONTRE_SUCCESS_ACTION
  | FETCH_RENCONTRE_ESSENTIALS_START_ACTION
  | SET_RENCONTRE_ACTION
  | FETCH_RENCONTRE_ESSENTIALS_SUCCESS_ACTION;

export function fetchRencontre(id: string, slugEquipe?: string): FETCH_RENCONTRE_START_ACTION {
  return {
    type: FETCH_RENCONTRE_START,
    payload: { id, slugEquipe }
  };
};

export function rencontreFetched(
  {
    rencontre,
    ranking,
    lastRencontres
  }: { rencontre: RencontreType, ranking: RankingType[], lastRencontres: RencontreSimpleType[] }
): FETCH_RENCONTRE_SUCCESS_ACTION {
  return {
    type: FETCH_RENCONTRE_SUCCESS,
    payload: {
      rencontre,
      ranking,
      lastRencontres,
    },
  };
};

export function rencontreFetchError(error: string): FETCH_RENCONTRE_FAILURE_ACTION {
  return {
    type: FETCH_RENCONTRE_FAILURE,
    payload: { error },
  };
};

export function setRencontre(actions: RencontreActionContainerType[]): SET_RENCONTRE_ACTION {
  return {
    type: SET_RENCONTRE,
    payload: {
      actions,
    }
  };
}

export function fetchRencontreArticle(id: string): FETCH_RENCONTRE_ESSENTIALS_START_ACTION {
  return {
    type: FETCH_RENCONTRE_ESSENTIALS_START,
    payload: { id }
  };
};

export function rencontreArticleFetched(rencontre: RencontreArticleType):
  FETCH_RENCONTRE_ESSENTIALS_SUCCESS_ACTION {
    return {
      type: FETCH_RENCONTRE_ESSENTIALS_SUCCESS,
      payload: { rencontre }
    };
};
