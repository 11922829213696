// @flow

import { takeLatest, all, select, take } from 'redux-saga/effects';
import TagManager from 'react-gtm-module';
import type { Saga } from 'redux-saga';

import { hitTagsArray } from 'constants/hitTagsConstant';

import { fetchSettings, FETCH_SETTINGS_SUCCESS } from 'actions/appActions';
import { FETCH_ARTICLE_SUCCESS } from 'actions/articleActions';
import { FETCH_EVENT_SUCCESS } from 'actions/eventActions';
import { FETCH_EVENTS_SUCCESS } from 'actions/eventsActions';
import { FETCH_FAQ_THEME_SUCCESS } from 'actions/faqActions';
import { SEND_VPV } from 'actions/hitTagsActions';
import { FETCH_ARTICLES_CATEGORIES_SUCCESS } from 'actions/newsletterActions';
import { FETCH_ARTICLES_CATEGORY_SUCCESS } from 'actions/newsletterCategoryActions';
import { SUBSCRIBE_NEWSLETTER_SUCCESS } from 'actions/newsletterSubscriptionActions';
import { FETCH_PAGE_LIST_SUCCESS, FETCH_PAGE_NODE_SUCCESS } from 'actions/pageActions';

import type { GeneratorType } from 'sagas/root';
import type { ActionType, StateType } from 'types/Actions';


export default function () {

  return function* hitTagsSaga(): GeneratorType {
    yield all(hitTagsArray.map(hitTag =>
      takeLatest(hitTag, throwHitVirtualPages)
    ));
  };

  function* throwHitVirtualPages(action: ActionType): Saga<void> {
    // get data from state
    let state: StateType = yield select();
    if (!state.appState.acceptCookie) {
      return;
    }

    let clubName = state.settingsState.title;

    // if empty, fetch data + wait for it to be finished to get data from state again
    if (clubName === '') {
      fetchSettings();
      yield take(FETCH_SETTINGS_SUCCESS);

      // take(FETCH_SETTINGS_SUCCESS) === Quand tu reçois un FETCH_SETTINGS_SUCCESS, tu passes à la suite.

      state = yield select();
      clubName = state.settingsState.title;
    }

    try {
      const pathname = window.location.pathname;
      const url = pathname.split('/');

      switch (action.type) {
        case SEND_VPV:
          // pages like /equipes/seniors-masculin-a-xv/informations-pratiques
          TagManager.dataLayer({
            dataLayer: {
              'event': 'virtualPageview',
              'virtualPageURL': pathname,
              'virtualPageTitle': `${url[3] ? url[3] : 'Général'} ${url[2]} ${clubName}`,
              'club': clubName,
            },
            dataLayerName: 'dataLayer'
          });
          break;
        case FETCH_ARTICLE_SUCCESS:
          // /actualites/category/article-name
          if (url && url[1] === "actualites" && url.length === 4) {
            TagManager.dataLayer({
              dataLayer: {
                'event': 'virtualPageview',
                'virtualPageURL': pathname,
                'virtualPageTitle': action.payload.article.title,
                'articleThematic': action.payload.article.label.name,
                'club': clubName,
              },
              dataLayerName: 'dataLayer'
            });
          }
          break;
        case FETCH_ARTICLES_CATEGORIES_SUCCESS:
          // homepage
          if (pathname === '/') {
            TagManager.dataLayer({
              dataLayer: {
                'event': 'virtualPageview',
                'virtualPageURL': pathname,
                'virtualPageTitle': 'Homepage',
                'club': clubName,
              },
              dataLayerName: 'dataLayer'
            });
          }
          break;
        case FETCH_ARTICLES_CATEGORY_SUCCESS:
          // /actualites/category
          if (url[1] === 'actualites') {
            TagManager.dataLayer({
              dataLayer: {
                'event': 'virtualPageview',
                'virtualPageURL': window.location.pathname,
                'virtualPageTitle': `Actualités de la categorie ${action.payload.articles[0].label.name}`,
                'articleThematic': action.payload.articles[0].label.name,
                'club': clubName,
              },
              dataLayerName: 'dataLayer'
            });
          }
          break;
        case FETCH_PAGE_LIST_SUCCESS:
          // /actualites only
          if (pathname === '/actualites') {
            TagManager.dataLayer({
              dataLayer: {
                'event': 'virtualPageview',
                'virtualPageURL': pathname,
                'virtualPageTitle': 'Dernières actualités',
                'club': clubName,
              },
              dataLayerName: 'dataLayer'
            });
            break;
          }

          // level 2 pages out of /actualites (above) and /evenements (FETCH_EVENTS_LIST)
          // and match pages
          // /mediateque/media
          if (
            (url && url[1] !== 'actualites' && (url.length === 2))
            || (url && url.includes('match'))
            || (url && url.includes('mediatheque') && url.length === 3)
          ) {
            TagManager.dataLayer({
              dataLayer: {
                'event': 'virtualPageview',
                'virtualPageURL': pathname,
                'virtualPageTitle': action.payload.pageList.title,
                'club': clubName,
              },
              dataLayerName: 'dataLayer'
            });
            break;
          }

          // don't send for /equipes/equipe/submenu() and an article (FETCH_ARTICLE_SUCCESS)
          if (
            (url[1] === 'equipes' && url.length === 3)
            || (url && url[1] !== 'actualites' && url.length === 4)
          ) {
            break;
          }

          break;
        case FETCH_PAGE_NODE_SUCCESS:
          // prevent this action type to trigger for /equipes/equipe...
          if (url && url[1] === 'equipes') {
            break;
          }

          // level 2 pages (ex : /club/le-mot-du-president)
          if (url && url[1] !== 'actualites' && url.length === 3) {
            TagManager.dataLayer({
              dataLayer: {
                'event': 'virtualPageview',
                'virtualPageURL': pathname,
                'virtualPageTitle': `${action.payload.pageNode.title}`,
                'club': clubName,
              },
              dataLayerName: 'dataLayer'
            });
            break;
          }
          break;
        case SUBSCRIBE_NEWSLETTER_SUCCESS:
          TagManager.dataLayer({
            dataLayer: {
              'event': 'EventGA',
              'eventCategory': 'user',
              'eventAction': 'inscription_newsletter',
              'eventLabel': pathname,
              'club': clubName,
            },
            dataLayerName: 'dataLayer'
          });
          break;
        case FETCH_EVENTS_SUCCESS:
          // /evenements and /events/yyyy/mmmmm
          TagManager.dataLayer({
            dataLayer: {
              'event': 'virtualPageview',
              'virtualPageURL': pathname,
              'virtualPageTitle': 'Evènements',
              'club': clubName,
            },
            dataLayerName: 'dataLayer'
          });
          break;
        case FETCH_EVENT_SUCCESS:
          TagManager.dataLayer({
            dataLayer: {
              'event': 'virtualPageview',
              'virtualPageURL': window.location.pathname,
              'virtualPageTitle': `Détail de l'événement ${action.payload.event.title}`,
              'club': clubName,
            },
            dataLayerName: 'dataLayer'
          });
          break;
        case FETCH_FAQ_THEME_SUCCESS:
          // /faq
          if (url[1] === 'faq') {
            TagManager.dataLayer({
              dataLayer: {
                'event': 'virtualPageview',
                'virtualPageURL': window.location.pathname,
                'virtualPageTitle': 'FAQ',
                'club': clubName,
              },
              dataLayerName: 'dataLayer'
            });
          }
          break;
        default:
      }
    } catch (e) {

    }
  }
}