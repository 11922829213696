import 'react-app-polyfill/ie11';
import 'core-js/es6/map';
import 'core-js/es6/set';
import 'core-js/es7/object';
import 'core-js/fn/object/entries';

import React from 'react';
import ReactDOM from 'react-dom';
import { initWindowApp, initManifest, initCss } from 'utils/windowUtils';
import registerServiceWorker from './registerServiceWorker';
import App from 'components/app/AppProvider';
import * as Sentry from '@sentry/browser';
import TagManager from 'react-gtm-module';

import packageJson from '../package.json';

import 'ie-array-find-polyfill';
import 'raf/polyfill';
import 'utils/ieUtils';
import './polyfill-includes';
import 'assets/css/style.css';
import 'assets/css/custom.css';

const tagManagerArgs = {
  gtmId: 'GTM-NSVZCFW',
  dataLayerName: 'dataLayer'
};

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: 'https://b1f4fcbe4bf0474d9f39b3a1d160e4cf@sentry.dev.ffr.fr/26',
    release: packageJson.version,
    environment: process.env.NODE_ENV
  });
  //TagManager.initialize(tagManagerArgs);
}

const userNotAdmin = () => {
  TagManager.initialize(tagManagerArgs);
};

initWindowApp();
initManifest();
initCss();

const root = document.getElementById('root');
const app = <App userNotAdmin={userNotAdmin} />;
ReactDOM.render(app, root);

registerServiceWorker();
