// @flow
import TagManager from 'react-gtm-module';

import type { StateType } from 'types/Actions';

import { store } from '../store';

export function handleTagEvent(
  eventCategory: string,
  eventAction: ?string,
  club: ?string,
  onClickActions: ?Function,
  eventLabel: ?string
) {
  return () => {
    const state: StateType = store.getState();

    if (state.appState.acceptCookie) {
      TagManager.dataLayer({
        dataLayer: {
          event: 'eventGA',
          eventCategory: eventCategory,
          eventAction: eventAction,
          eventLabel: eventLabel,
          club: club
        },
        dataLayerName: 'dataLayer'
      });
    }

    if (onClickActions) {
      onClickActions();
    }
  };
}

export const getSocialName = (props: Object) => {
  const { isFacebook, isInstagram,isYoutube, isLinkedin, isMail, isTwitter } = props;
  if (isFacebook) {
    return 'facebook';
  } else if (isTwitter) {
    return 'twitter';
  } else if (isInstagram) {
    return 'instagram';
  } else if (isYoutube) {
    return 'youtube';
  } else if (isLinkedin) {
    return 'linkedin';
  } else if (isMail) {
    return 'mail';
  }
  return '';
};

export const sendTags = (category: string, action: string, label?: string, club: ?string) => {
  const state: StateType = store.getState();
  if (!state.appState.acceptCookie) {
    return;
  }

  TagManager.dataLayer({
    dataLayer: {
      event: 'eventGA',
      eventCategory: category,
      eventAction: action,
      eventLabel: label,
      club: club
    },
    dataLayerName: 'dataLayer'
  });
};
