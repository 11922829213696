// @flow
import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';

import './cookie.css';

type OwnProps = {};

export type StateProps = {
  showCookieInfo: boolean,
  title: string,
  cookies: number,
  cgus: string,
  cgu: number,
};

export type DispatchProps = {
  closeCookieInfo: (accept: boolean) => void
};

type Props = OwnProps & StateProps & DispatchProps;

const cookieEndContent =
  ", vous acceptez les CGU ainsi que l'utilisation de cookies et de technologies similaires, y compris celles" +
  ' de partenaires tiers, pour vous proposer des contenus pertinents et des publicités ciblées en fonction' +
  " de vos centres d'intérêts.";

class Cookie extends PureComponent<Props> {
  acceptCookies = () => this.props.closeCookieInfo(true);

  refuseCookies = () => this.props.closeCookieInfo(false);

  render() {
    const { title, cookies, cgus, cgu, showCookieInfo } = this.props;
    if (!showCookieInfo) {
      return null;
    }

    return (
      <div className="cookie-container">
        <div className="box cookie">
          <p>
            En poursuivant votre navigation sur{' '}
            {title}
            {cookieEndContent}
            <br />
            {cookies !== 0 &&
              <Link to="/politique-de-cookies">
                En savoir plus sur les cookies
                </Link>
            }
            {(cgus || (cgu && cgu > 0)) &&
              <a href={cgus ? cgus : "/cgu"} title="Conditions générales d'utilisations">
                {' '}Afficher les CGUs
              </a>}
          </p>

          <div className="btn-container">
            <button onClick={this.acceptCookies} className="btn btn--primary btn--full">Accepter les cookies</button>
            <button onClick={this.refuseCookies} className="btn btn--primary btn--full">Refuser</button>
          </div>

          <button className="btn cookie-close" onClick={this.refuseCookies}>
            Continuer sans accepter &#10142;
          </button>
        </div>
      </div>
    );
  }
}

export default Cookie;
