// @flow
import React, { PureComponent } from 'react';
import { Route } from 'react-router-dom';
import * as Sentry from '@sentry/browser';

import Routes from './../../routes';

import { withHelmet } from 'infrastructure/seo/Helmet';
import { STATUS_SUCCESS } from 'constants/statusConstants';

import AppOffline from 'components/app/AppOffline';
import Partners from 'containers/footer/PartnersContainer';
import CookieContainer from 'containers/footer/CookieContainer';
import Header from 'components/header/Header';
import Footer from 'components/footer/Footer';
import ModalContainer from 'containers/fragments/ModalContainer';

import type { FaviconType } from 'types/Settings';
import type { Status } from 'types/Status';
import type { PartnerType } from 'types/Partner';
import type { RouterHistory } from 'react-router-dom';

import {
  headerBlacklistWithSlugPaths,
  footerBlacklistWithSlugPaths,
  footerBlacklistPaths
} from 'constants/menuConstants';

import './style_surcharge.css';

// @TODO: Change lorsque l'on passera en prod
//const INTERVAL_FETCH_LIVE = 30000;
const INTERVAL_FETCH_LIVE = 10000;

type HistoryProps = {
  history: RouterHistory
};

export type StateProps = {
  mode_travaux: boolean,
  user_id: number,
  favicons: FaviconType,
  titleClub: string,
  linkPreview: string,
  partnersStatus: Status,
  partners: PartnerType[]
};

export type DispatchProps = {
  fetchSettings: () => void,
  fetchMenu: () => void,
  fetchCategories: () => void,
  fetchPartners: () => void,
  fetchPageDetailPreview: (id: number) => void,
  fetchRencontreLive: () => void,
  keycloakConnect: () => void
};

type Props = {
  location: any,
  userNotAdmin: () => void
} & DispatchProps &
  StateProps &
  HistoryProps;

type State = {
  userAdmin: boolean
};

class App extends PureComponent<Props, State> {
  state: State = {
    userAdmin: true
  };
  _intervalId = null;
  onInputEvent = (e: FocusEvent) => {
    if (e.type === 'focus') {
      window.inputFocused = true;
    } else if (e.type === 'blur') {
      window.inputFocused = false;
    }
  };

  fetchRencontreLive = () => {
    const { fetchRencontreLive } = this.props;
    fetchRencontreLive();
  };

  componentDidMount() {
    const inputs = document.querySelectorAll('input');
    const {
      location: { search, pathname },
      history,
      linkPreview,
      fetchPageDetailPreview,
      fetchSettings,
      fetchMenu,
      fetchCategories,
      fetchPartners,
      fetchRencontreLive,
      keycloakConnect
    } = this.props;
    const { userAdmin } = this.state;

    /**
     * Est -on en mode preview
     */
    if (search && /preview=true/.test(search)) {
      const param = search.replace('&preview=true', '');
      if (/\?p=/.test(search)) {
        /**
         * Une actualité
         */
        history.push('/actualites/preview/post' + param);
        window.location.reload();
      } else if (/\?page_id=/.test(search)) {
        /**
         * Une page
         */
        if (linkPreview && pathname !== linkPreview) {
          history.push(linkPreview);
          window.location.reload();
        } else {
          let id = search;
          id = id.replace('?page_id=', '');
          fetchPageDetailPreview(parseInt(id, 10));
        }
      }
    }

    inputs.forEach(input => {
      input.setAttribute('data-mounted', '1');
      input.addEventListener('focus', this.onInputEvent, false);
      input.addEventListener('blur', this.onInputEvent, false);
    });

    fetchSettings();
    fetchMenu();
    fetchCategories();
    fetchPartners();
    fetchRencontreLive();
    keycloakConnect();

    if (this._intervalId === null) {
      this._intervalId = setInterval(this.fetchRencontreLive, INTERVAL_FETCH_LIVE);
    }

    if (this.props.user_id !== 0 && userAdmin !== true) {
      this.setState({ userAdmin: true });
    }
  }

  componentDidUpdate(prevProps: Props) {
    const {
      location: { search, pathname },
      linkPreview,
      history,
      user_id
    } = this.props;
    const { pathname: prevPathname } = prevProps.location;
    const { userAdmin } = this.state;

    if (prevProps.user_id !== user_id && user_id === 0 && userAdmin === true) {
      this.setState({ userAdmin: false });
    }

    if (prevProps !== this.props) {
      const inputs = document.querySelectorAll('input');
      inputs.forEach(input => {
        const isMounted = input.getAttribute('data-mounted') === '1';
        if (!isMounted) {
          input.setAttribute('data-mounted', '1');
          input.addEventListener('focus', this.onInputEvent, false);
          input.addEventListener('blur', this.onInputEvent, false);
        }
      });
    }

    if (/\?page_id=/.test(search) && linkPreview !== '' && prevProps.linkPreview !== linkPreview) {
      history.push(linkPreview);
      window.location.reload();
    } else {
      const splited = pathname.split('/');
      if (splited[1] === 'faq' || splited[1] === 'contact') {
        if (!splited[2]) {
          window.scrollTo(0, 0);
        } else {
          window.scrollTo(0, 250);
        }
      } else if (splited[1] === 'actualites') {
        window.scrollTo(0, 0);
      } else if (splited[1] === 'evenements') {
        const prevSplited = prevPathname.split('/');
        if (prevSplited[1] === 'evenements') {
          window.scrollTo(0, 450);
        } else {
          window.scrollTo(0, 0);
        }
      } else if (prevPathname.split('/')[1] === 'recherche' && splited[1] === 'recherche') {
        window.scrollTo(0, 350);
      } else {
        window.scrollTo(0, 0);
      }
    }
  }

  renderFooter() {
    const {
      partners,
      location: { pathname },
      partnersStatus
    } = this.props;
    if (footerBlacklistPaths.includes(pathname)) return null;
    else if (footerBlacklistWithSlugPaths.includes(pathname.split('/')[1])) return null;
    return (
      <>
        {partnersStatus === STATUS_SUCCESS && partners.length > 0 && <Partners />}
        <Footer />
      </>
    );
  }

  componentDidCatch(error, errorInfo) {
    const { history } = this.props;

    if (process.env.NODE_ENV === 'production') {
      Sentry.withScope(scope => {
        scope.setExtras(errorInfo);
        Sentry.captureException(error);
      });
      history.push('/404');
      window.location.reload();
    }
  }

  renderHeader() {
    const { pathname } = this.props.location;
    if (headerBlacklistWithSlugPaths.includes(pathname.split('/')[1])) return null;
    return <Header />;
  }

  componentWillUnmount() {
    const inputs = document.querySelectorAll('input');
    inputs.forEach(input => {
      input.removeEventListener('focus', this.onInputEvent, false);
      input.removeEventListener('blur', this.onInputEvent, false);
    });

    if (this._intervalId !== null) {
      clearInterval(this._intervalId);
    }
  }

  renderOffLine = () => {
    return (
      <>
        <ModalContainer />
        <Route component={AppOffline} />
        <CookieContainer />
      </>
    );
  };

  render() {
    const { mode_travaux, user_id } = this.props;
    const { userAdmin } = this.state;
    if (mode_travaux && !user_id) {
      return this.renderOffLine();
    }
    if (userAdmin === false) {
      this.props.userNotAdmin();
    }
    return (
      <>
        <ModalContainer />
        {this.renderHeader()}
        <Routes />
        {this.renderFooter()}

        <CookieContainer />
      </>
    );
  }
}

// [SEO] Set default meta tags here !
function renderHelmetDefault(Helmet, pageProps) {
  return (
    <Helmet titleTemplate={`%s`} defaultTitle={pageProps.titleClub ? pageProps.titleClub : window.location.href}>
      <link rel="shortcut icon" href={pageProps.favicons['favicon-32']} />
      <link rel="apple-touch-icon" sizes="180x180" href={pageProps.favicons['favicon-180']} />
      <link rel="icon" type="image/png" sizes="32x32" href={pageProps.favicons['favicon-32']} />
      <link rel="icon" type="image/png" sizes="16x16" href={pageProps.favicons['favicon-16']} />
    </Helmet>
  );
}

export default withHelmet(App)(renderHelmetDefault);
