// @flow

import {
	APP_SET_SHOW_ALERT_INFO,
  APP_SET_SHOW_COOKIE_INFO,
  APP_USER_SUBSCRIBE_NEWSLETTER,
} from 'actions/appActions';

import type { ActionType } from 'types/Actions';

export type State = {
  showAlertInfo: string,
  showCookieInfo: boolean,
  newsletterSubscribed: boolean,
  acceptCookie: boolean,
  acceptCookieDate: Date | null,
};

const initialState: State = {
  showAlertInfo: '',
  showCookieInfo: true,
  newsletterSubscribed: false,
  acceptCookie: false,
  acceptCookieDate: null,
};

export default function(state: State = initialState, action: ActionType): State {
  switch (action.type) {
    case APP_SET_SHOW_ALERT_INFO:
      return {
        ...state,
        showAlertInfo: action.payload.showAlertInfo,
      };
    case APP_SET_SHOW_COOKIE_INFO:
      return {
        ...state,
        showCookieInfo: action.payload.showCookieInfo,
        acceptCookie: action.payload.acceptCookie,
        acceptCookieDate: action.payload.showCookieInfo ? null : new Date(),
      };
    case APP_USER_SUBSCRIBE_NEWSLETTER:
      return {
        ...state,
        newsletterSubscribed: action.payload.newsletterSubscribed,
      };
    default:
      return state;
  }
}
