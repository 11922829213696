// @flow

import { STATUS_LOADING, STATUS_FAILURE, STATUS_SUCCESS } from 'constants/statusConstants';
import {
  FETCH_RENCONTRE_START,
  FETCH_RENCONTRE_SUCCESS,
  FETCH_RENCONTRE_FAILURE,
  FETCH_RENCONTRE_ESSENTIALS_SUCCESS,
  SET_RENCONTRE,
} from 'actions/rencontreActions';
import { nullRencontreArticle, nullRencontre } from 'types/Rencontre';

import type {
  RencontreType,
  RencontreSimpleType,
  RencontreArticleType,
} from 'types/Rencontre';
import type { RankingType } from 'types/Ranking';
import type { Status } from 'types/Status';
import type { ActionType } from 'types/Actions';

export type State = {
  rencontre: RencontreType,
  lastRencontres: RencontreSimpleType[],
  ranking: RankingType[],
  rencontreArticle: RencontreArticleType,
  status: Status,
  message: string,
};

const initialState: State = {
  rencontre: nullRencontre,
  rencontreArticle: nullRencontreArticle,
  status: STATUS_LOADING,
  lastRencontres: [],
  ranking: [],
  message: '',
};

export default function(state: State = initialState, action: ActionType): State {
  switch (action.type) {
    case FETCH_RENCONTRE_START:
      return {
        ...state,
        status: STATUS_LOADING,
      };
    case SET_RENCONTRE:
      return {
        ...state,
        rencontre: {
          ...state.rencontre,
          actions: action.payload.actions,
        },
      };
    case FETCH_RENCONTRE_SUCCESS:
      return {
        ...state,
        rencontre: action.payload.rencontre,
        lastRencontres: action.payload.lastRencontres,
        ranking: action.payload.ranking,
        status: STATUS_SUCCESS,
      };
    case FETCH_RENCONTRE_ESSENTIALS_SUCCESS:
      return {
        ...state,
        rencontreArticle: action.payload.rencontre,
        status: STATUS_SUCCESS
      };
    case FETCH_RENCONTRE_FAILURE:
      return {
        ...state,
        status: STATUS_FAILURE,
        message: action.payload.error,
      };
    default:
      return state;
  }
}
